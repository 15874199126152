<template>
  <div class="w-100 h-100">
    <div
      class="custom-container d-flex justify-content-center position-relative py-4"
    >
      <!-- LEFT STARTS HERE  -->
      <div class="custom-left w-50 pr-4">
        <div
          class="custom-tick d-flex justify-content-center align-items-center"
        >
          &#10003;
        </div>
        <div class="font-butler font-large color-black my-1 ">
          Your rental has been listed
        </div>
        <div class="font-regular mb-1">
          Here’s a few things to get you started
        </div>
        <div class="font-weight-bold font-regular color-black mb-1">
          Next Steps
        </div>
        <div class="custom-line w-100"></div>

        <div class="w-100 p-2">
          <div class="font-weight-bold font-regular color-black">
            Get Payments
          </div>
          <div>
            Send all your future payments to your bank or debit card for free
          </div>
          <button class="custom-payout cursor-pointer">
            Setup Payouts
          </button>
        </div>
        <div class="custom-line w-100"></div>
        <div class="mt-2 d-flex justify-content-end">
          <button class="custom-continue" @click="sendToUserDashboard">
            Continue
          </button>
        </div>
      </div>

      <!-- RIGHT STARTS HERE  -->
      <div
        class="custom-right w-50 d-flex justify-content-start px-4 flex-column"
      >
        <img
          :src="houseImages[0].image_url"
          alt="No image"
        />
        <div
          class="font-butler font-mid-large color-black my-1 font-weight-bold"
        >
          {{ roomType }} in, {{ houseData.city }}
        </div>
        <div class="custom-line w-100"></div>
        <div
          class="d-flex justify-content-between align-items-center w-100 position-relative"
        >
          <div
            class="custom-box p-1 d-flex flex-column align-items-center justify-content-between"
          >
            <img :src="specialIcon" alt="No Image" />
            <div>{{ houseData.beds }} Bedrooms</div>
          </div>
          <div
            class="custom-box p-1 d-flex flex-column align-items-center justify-content-between"
          >
            <img :src="specialIcon" alt="No Image" />
            <div>{{ houseData.baths }} Bathroom</div>
          </div>
          <div
            class="custom-box p-1 d-flex flex-column align-items-center justify-content-between"
          >
            <img :src="specialIcon" alt="No Image" />
            <div>
              Entire Place
            </div>
          </div>
        </div>
        <div class="custom-line w-100"></div>
        <div
          class="font-butler font-mid-large color-black my-1 font-weight-bold"
        >
          Lister’s Overview
        </div>
        <div class="mb-1">
          What are you looking for in a tenant? Some random description, what do
          you love about this place? Why makes this place a different from other
          places.
        </div>
        <div class="custom-line w-100"></div>
        <div
          class="mt-1 font-regular d-flex justify-content-between align-items-end"
        >
          <span v-if="houseData.duration_of_stay == 'Monthly'"
            ><span class="color-black" v-if="houseData.currency == 'USD'"
              >${{ houseData.estimated_price }}</span
            >
            per month</span
          >
          <span v-if="houseData.duration_of_stay == 'Monthly'"
            ><span class="color-black" v-if="houseData.currency == 'INR'"
              >₹{{ houseData.estimated_price }}</span
            >
            per month</span
          >

          <span v-if="houseData.duration_of_stay == 'Nightly'"
            ><span class="color-black" v-if="houseData.currency == 'USD'"
              >${{ houseData.estimated_price }}</span
            >
            per month</span
          >
          <span v-if="houseData.duration_of_stay == 'Nightly'"
            ><span class="color-black" v-if="houseData.currency == 'INR'"
              >₹{{ houseData.estimated_price }}</span
            >
            per month</span
          >

          <span v-if="houseData.duration_of_stay == 'Long Term'"
            ><span class="color-black" v-if="houseData.currency == 'USD'"
              >${{ houseData.estimated_price }}</span
            >
            per year</span
          >
          <span v-if="houseData.duration_of_stay == 'LongTerm'"
            ><span class="color-black" v-if="houseData.currency == 'INR'"
              >₹{{ houseData.estimated_price }}</span
            >
            per year</span
          >

          <small>Move in {{ houseData.move_in_date }}</small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import specialIcon from "@/assets/icons/special.png";
import { fetchListingData, getHouseImages } from '@/utils/api.js';
export default {
    data(){
        return {
            specialIcon,
            houseData : [],
            id:'',
            roomType:'',
            houseImages:[],
        }
    },
    methods:{
        sendToUserDashboard(){
            this.$router.push({name:"userdashboard"});
        },


        async getFetchListingData(){

            const data = await fetchListingData(this.id);
            this.houseData = data.house;
            this.houseData.move_in_date = this.formatDate(this.houseData.move_in_date)
            if(this.houseData.isPrivate){
                this.roomType = 'Private Room'
            }
            else{
                this.roomType = 'Entire Place'
            }
        },


        formatDate(date) {
        console.log("this is the date!!!", date);
        const month = [
            "Jan",
            "Feb",
            "Mar",
            "April",
            "May",
            "June",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
        ];
        const dd = parseInt(date.slice(8, 10));
        const m = parseInt(date.slice(5, 7));
        const mm = month[m - 1];

        const year = parseInt(date.slice(0, 4));
        let prefix;

        if (dd == 11 || dd == 12 || dd == 13) {
            prefix = "th";
        } else if (dd % 10 == 1) {
            prefix = "st";
        } else if (dd % 10 == 2) {
            prefix = "nd";
        } else if (dd % 10 == 3) {
            prefix = "rd";
        } else {
            prefix = "th";
        }
        console.log(m, mm, prefix);

        const blogDate = `${dd}${prefix} ${mm}, ${year}`;
        return blogDate;
        },
        async useGetImages(){

            const data = await getHouseImages(this.id);
            this.houseImages = [...data.data];
            console.log('House images:',this.houseImages);

        }
    },

    mounted(){
        this.id = this.$route.params.id;
        this.getFetchListingData();
        this.useGetImages();
    }
}
</script>
<style lang="scss" scoped>
.custom-container {
  width: 80%;
  height: 100%;
  margin: 0 auto;
}
.custom-line {
  height: 1px;
  background: rgba($color: #000000, $alpha: 0.1);
}
.custom-left {
  border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
  .custom-tick {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    font-size: 1.7rem;
    color: #0d6efd;
    background-color: rgba($color: #0d6efd, $alpha: 0.2);
  }
  .custom-payout {
    font-size: 0.9rem;
    padding: 5px 1rem;
    border-radius: 5px;
    border: 1px solid #0d6efd;
    margin-top: 1rem;
    color: #0d6efd;
    background-color: #fff;
    transition: all 0.3s ease;
    &:hover {
      color: #fff;
      background-color: #0d6efd;
    }
  }
  .custom-continue {
    font-size: 0.9rem;
    padding: 5px 1rem;
    border-radius: 5px;
    border: 1px solid #0d6efd;
    margin-top: 1rem;
    color: #fff;
    background-color: #0d6efd;
    transition: all 0.3s ease;
    &:hover {
      color: #0d6efd;
      background-color: #fff;
    }
  }
}
.custom-right {
  img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }

  .custom-box {
    height: 6rem;
    width: 10rem;
    img {
      height: 1.7rem;
      width: auto;
    }
  }
}
</style>
